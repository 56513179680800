<template>
    <div id="error">
        <main class="wrapper noselect">
            <div class="content">
                <h1>404</h1>
                <p>This page doesn't exist. Sorry!</p>
                <button class="primary-button hero-button" @click="backHome">Go back home</button>
                <a class="link" target="_top" href="mailto:support@mailpal.cc">Report a problem</a>
            </div>
        </main>
        <Footer />
    </div>
</template>

<script>
    import Footer from '@/components/Footer'

    export default {
        name: '404',
        components: {
            Footer
        },
        methods: {
            backHome(){
                this.$router.replace({ path: '/' })
            }
        }
    }
</script>

<style>
    html,
    body{
        color: var(--font);
        background: var(--background-dark);
    }

    .wrapper {
        height: 100vh;
        margin: auto;
        text-align: center;
        -webkit-animation: fadein 1s;
        -moz-animation: fadein 1s;
        -ms-animation: fadein 1s;
        -o-animation: fadein 1s;
        animation: fadein 1s;
    }

    .wrapper h1{
        text-align:center;
        margin-bottom:40px;
        font-size: 60px;
    }

    .wrapper p{
        color: var(--font-light);
        font-size: 20px;
    }

    .primary-button{
        -moz-appearance: none;
        appearance: none;
        border: 0;
        padding: 15px 15px;
        font-weight: 700;
        border-radius: 5px;
        cursor: pointer;
    }

    .primary-button:active{
        transform: scale(0.95);
    }

    .hero-button{
        background: var(--primary);
        color: var(--font-dark);
        font-size: 15px;
        border-bottom: 5px solid #63C6B1;
    }

    .link {
        text-decoration: none;
        color: var(--font-dark);
        margin-top: 1rem;
        display: block;
    }

    .content {
        margin: 0;
        text-align: center;
    }
</style>