<template>
	<footer class="footer noselect">
		<div class="logo">
			<a href="/">
				<h1>MailPal</h1>
			</a>
			<p class="hover-here">Made with <svg id="heart" xmlns="http://www.w3.org/2000/svg" height="26" width="22" viewBox="0 0 27 15"><path class="heart-svg" d="M23.538,3.807a6.963,6.963,0,0,0-9.5.692l-1,1.034-1-1.034a6.962,6.962,0,0,0-9.5-.692,7.311,7.311,0,0,0-.5,10.585l9.852,10.173a1.6,1.6,0,0,0,2.306,0l9.852-10.173a7.306,7.306,0,0,0-.5-10.585Z" transform="translate(0.001 -2.248)" /></svg> in Germany</p>
		</div>
		<div class="footer-list">
			<span>Navigation</span>
			<a href="/about">About</a>
			<a href="#pricing">Pricing</a>
			<a href="https://app.mailpal.cc/login">Login</a>
			<a href="https://app.mailpal.cc/signup">Sign up</a>
		</div>
		<div class="footer-list">
			<span>Legal stuff</span>
			<a href="/terms">Terms & Conditions</a>
			<a href="/privacy">Privacy Policy</a>
		</div>
		<div class="footer-list">
			<span>Questions?</span>
			<a href="mailto:hello@mailpal.cc">hello@mailpal.cc</a>
		</div>
    </footer>
</template>

<script>
	export default {
		name: 'Footer',
			computed: {
			mobile: function(){
				return this.$store.state.mobile;
			},
		},
	}
</script>

<style scoped>
	.footer {
		width: 100%;
		background-color: var(--background);
		display: flex;
		justify-content: space-evenly;
		padding: 22px 0px;
	}

	.logo {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.logo a {
		color: var(--font);
		text-decoration: none;
	}

	.logo h1 {
		margin: 0;
	}

	.logo p {
		color: var(--font-dark);
		font-weight: 700;
		margin: 0;
	}

	.footer-list {
		text-align: left;
		display: flex;
		flex-direction: column;
		justify-content: center;
		font-weight: 700;
	}

	.footer-list span {
		color: var(--font-dark);
	}

	.footer-list a {
		color: var(--font-light);
		text-decoration: none;
	}

	.heart-svg {
		fill: var(--red);
	}

	#heart {
		position: relative;
		top: 0.25em;
	}

	.hover-here:hover #heart {
		animation: heartbeat 1s infinite;
	}

	@keyframes heartbeat {
		0% {
			transform: scale(.75);
		}

		20% {
			transform: scale(1);
		}

		40% {
			transform: scale(.75);
		}

		60% {
			transform: scale(1);
		}

		80% {
			transform: scale(.75);
		}

		100% {
			transform: scale(.75);
		}
	}

	@media only screen and (max-width: 900px) {
		.logo h1{
			font-size: 25px;
		}

		.logo p{
			font-size: 15px;
		}
	}

	@media only screen and (max-width: 800px) {
        .footer {
            flex-direction: column-reverse;
			align-items: center;
		}
		.footer-list,
		.logo{
			margin-top: 1rem;
			margin-bottom: 1rem;
			width: 80%;
			max-width: 200px;
		}
    }
</style>